import React, {useState} from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import {ShoppingCart} from "../../utils/shop"
import OrderForm from "../../components/OrderForm/OrderForm";

const IndexPage = ({location, data}) => {

    const [cart, setCart] = useState(new ShoppingCart());
    const locale = getLocaleFromPath(location.pathname);

    if (cart.size() === 0) {
        if (typeof window !== `undefined`){
            window.location = "/"+locale+"/kosik";
        }
    }

    return (
        <Layout location={location}>
            <OrderForm cart={cart} locale={locale}/>
        </Layout>
    );
};

export default withI18n()(IndexPage);

